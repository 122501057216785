export enum TextKey {
  HEADER = "header",
  MAP_HEADER = "map-header",
  MAP_FORM_EMAIL = "map-form-email",
  MAP_FORM_POSTAL = "map-form-postal",
  MAP_FORM_OTIN = "map-form-optin",
  MAP_FORM_CTA = "map-form-cta",
  MAP_FORM_SUCCESS = "map-form-success",
  MAP_SHARE_VOLUNTEER = "map-share-volunteer",
  MAP_SHARE_FACEBOOK = "map-share-facebook",
  MAP_SHARE_TWITTER = "map-share-twitter",
  MAP_SHARE_WHATSAPP = "map-share-whatsapp",
  MAP_SHARE_MESSENGER = "map-share-messenger",
  MAP_SHARE_TELEGRAM = "map-share-telegram",
  MAP_SHARE_LEGALS = "map-share-legals",
  MAP_CTA = "map-cta",
  ACTIONS_HEADER = "actions-header",
  LEGALS = "legals",
  LEGALS_CTA = "legals-cta",
  BACK_TO_HOMEPAGE = "back-to-homepage",
  NOT_FOUND = "404-not-found",
}
