export const BLACK = "#000000";
export const RED = "#ED340B";
export const DARK_PURPLE = "#2E0427";
export const PURPLE = "#9674E2";
export const GREEN = "#4DD683";
export const PINK = "#FEBDE6";
export const YELLOW = "#FEE66D";

export const PRIMARY = RED;
export const BACKGROUND = 'white';
export const TEXT_DARK = 'white';
export const TEXT_LIGHT = BLACK;
